const registerStep = [
    {
        key: 0,
        step: 'step1',
        content: '3월, 9월에 에브리타임 게시판에서 공고를 확인합니다.',
    },
    {
        key: 1,
        step: 'step2',
        content: '공고에 올라온 구글폼 링크에서 원하는 파트를 지원합니다!',
    },
    {
        key: 2,
        step: 'step3',
        content: '서류 합격 시 적합성 면접을 진행합니다!',
    },
    { key: 3, step: 'step4', content: '최종 합격 시 활동을 시작합니다.' },
];

export default registerStep;
