import appcenter_splash from '../img/welcomeImages/splash/appcenter_splash.jpg';
import appcenter_icon from '../img/welcomeImages/icon/appcenter_icon.png';
import inubus_splash from '../img/welcomeImages/splash/inubus_splash.jpg';
import inubus_icon from '../img/welcomeImages/icon/inubus_icon.png';
import inucafeteria_splash from '../img/welcomeImages/splash/inucafeteria_splash.jpg';
import inucafeteria_icon from '../img/welcomeImages/icon/inucafeteria_icon.png';
import inuit_splash from '../img/welcomeImages/splash/inuit_splash.jpg';
import inuit_icon from '../img/welcomeImages/icon/inuit_icon.png';
import mapus_splash from '../img/welcomeImages/splash/mapus_splash.jpg';
import mapus_icon from '../img/welcomeImages/icon/mapus_icon.png';
import noticealarm_splash from '../img/welcomeImages/splash/noticealarm_splash.jpg';
import noticealarm_icon from '../img/welcomeImages/icon/noticealarm_icon.png';
import thisweather_splash from '../img/welcomeImages/splash/thisweather_splash.jpg';
import thisweather_icon from '../img/welcomeImages/icon/thisweather_icon.png';
import uniletter_splash from '../img/welcomeImages/splash/uniletter_splash.jpg';
import uniletter_icon from '../img/welcomeImages/icon/uniletter_icon.png';

export const mainImages = [
    {
        id: 1,
        title: '인천대학교 앱센터',
        splash: appcenter_splash,
        icon: appcenter_icon,
    },
    {
        id: 2,
        title: 'INU BUS',
        splash: inubus_splash,
        icon: inubus_icon,
    },
    {
        id: 3,
        title: 'INU 카페테리아',
        splash: inucafeteria_splash,
        icon: inucafeteria_icon,
    },
    {
        id: 4,
        title: 'INUIT',
        splash: inuit_splash,
        icon: inuit_icon,
    },
    {
        id: 5,
        title: 'MAPUS',
        splash: mapus_splash,
        icon: mapus_icon,
    },
    {
        id: 6,
        title: 'INU 공지알리미',
        splash: noticealarm_splash,
        icon: noticealarm_icon,
    },
    {
        id: 7,
        title: 'INU 이런날씨',
        splash: thisweather_splash,
        icon: thisweather_icon,
    },
    {
        id: 8,
        title: '유니레터',
        splash: uniletter_splash,
        icon: uniletter_icon,
    },
];
